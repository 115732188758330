import React, { ReactElement } from "react";

import { FeaturedCompany } from "interfaces/featuredCompanies";
import { CompanyCard } from "./CompanyCard";
import styles from "./ExploreCompanies.module.scss";

export type IExploreCompanies = {
    featureHeading: string,
    companies: FeaturedCompany[],
    exploreCompaniesSubheading?: string,
    heading?: string;
}

/**
 * Renders a section displaying a list of featured companies.
 */
const ExploreCompanies = (props: IExploreCompanies): ReactElement => {
    const {
        featureHeading = "",
        companies = [],
        exploreCompaniesSubheading = "",
        heading = "",
    } = props;

    const liveCompanies = companies.filter((company) => company.status === "live");

    if (!liveCompanies.length) return null;

    return (
        <section className={styles.section}>
            {heading && (<p className={styles.heading}>{heading}</p>)}
            {exploreCompaniesSubheading && (<p className={styles.subheading}>{exploreCompaniesSubheading}</p>)}

            <div className={styles.grid}>
                {featureHeading && <h3 className={styles.sectionTitle}>{featureHeading}</h3>}
                {liveCompanies.map((company: FeaturedCompany, index: number) => (
                    <CompanyCard
                        key={company.title}
                        company={company}
                        index={index + 1}
                        featureHeading={featureHeading}
                        lazy={index > 0}
                    />
                ))}
            </div>
        </section>
    );
};

export {
    ExploreCompanies
};
